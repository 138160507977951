jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-left"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-wrapper").fitVids({ ignore: "nofit" });
		$(".homeboxes").fitVids({ ignore: "nofit" });
	}

	/* Scroll to top */
	$("#topLink").click(function() {
		$('html,body').animate({
			scrollTop: $("#Top").offset().top},
		800
		);
	});

	/* Accessible menu */
	$(".nav-drop-wrap:has('.container-wrapper')").addClass("has-dropdown");

	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".nav-drop-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();


	/* Webflow: Interactions: Init */
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"ken-burns-slideshow-pan","name":"Ken Burns Slideshow Pan","value":{"style":{},"triggers":[{"type":"slider","loopA":true,"loopB":true,"stepsA":[{"wait":"15000ms","transition":"transform 15000ms ease 0","x":"100px","y":"0px","z":"0px"}],"stepsB":[{"wait":"20000ms","transition":"transform 20000ms ease 0","x":"0px","y":"0px","z":"0px"}]}]}},
		{"slug":"dropdown-smooth-open","name":"Dropdown Smooth Open","value":{"style":{},"triggers":[{"type":"dropdown","stepsA":[{"opacity":0,"height":"0px"},{"opacity":1,"height":"auto","transition":"opacity 350ms ease 0, height 350ms ease 0"}],"stepsB":[{"height":"0px","transition":"opacity 100ms ease 0ms, height 100ms ease 0ms"}]}]}},
		{"slug":"for-two-part-button-toggle","name":"For Two Part Button Toggle","value":{"style":{},"triggers":[{"type":"click","selector":".two-part-button-list","siblings":true,"preserve3d":true,"stepsA":[{"transition":"transform 750ms ease 0","x":"-100%","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"for-two-part-list-back-button","name":"For Two Part List Back Button","value":{"style":{},"triggers":[{"type":"click","selector":".two-part-button-list","preserve3d":true,"stepsA":[{"transition":"transform 750ms ease 0","x":"100%","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"logo-image-on-load","name":"Logo Image On Load","value":{"style":{"width":"100%"},"triggers":[]}},
		{"slug":"expand-search-bar","name":"Expand Search Bar","value":{"style":{},"triggers":[]}},
		{"slug":"nav-accent-reveal","name":"Nav Accent Reveal","value":{"style":{},"triggers":[{"type":"dropdown","selector":".nav-accent","descend":true,"stepsA":[{"opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 200 ease 0"}]}]}},
		{"slug":"nav-accent-reveal-2","name":"Nav Accent Reveal 2","value":{"style":{},"triggers":[{"type":"dropdown","selector":".nav-accent","stepsA":[{"opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 200 ease 0"}]}]}},
		{"slug":"callout-reveal","name":"Callout Reveal","value":{"style":{"opacity":0,"x":"0px","y":"300px","z":"0px","scaleX":0.9500000000000006,"scaleY":0.9500000000000006,"scaleZ":1},"triggers":[{"type":"scroll","offsetBot":"8%","stepsA":[{"opacity":1,"transition":"transform 500ms ease-out 0, opacity 600ms ease-out 0","x":"0px","y":"0px","z":"0px","scaleX":1,"scaleY":1,"scaleZ":1}],"stepsB":[]}]}},
		{"slug":"testimonial-reveal","name":"Testimonial Reveal","value":{"style":{"opacity":0,"x":"0px","y":"300px","z":"0px","scaleX":0.9500000000000006,"scaleY":0.9500000000000006,"scaleZ":1},"triggers":[{"type":"scroll","offsetBot":"5%","stepsA":[{"opacity":1,"transition":"transform 500ms ease-out 0, opacity 600ms ease-out 0","x":"0px","y":"0px","z":"0px","scaleX":1,"scaleY":1,"scaleZ":1}],"stepsB":[]}]}},
		{"slug":"mobile-search-dd","name":"Mobile Search DD","value":{"style":{},"triggers":[{"type":"click","selector":".mobile-search-wrap","stepsA":[{"height":"auto","transition":"height 200ms ease 0"}],"stepsB":[{"height":"0px","transition":"height 200 ease 0"}]}]}},
		{"slug":"new-interaction","name":"New Interaction","value":{"style":{},"triggers":[]}},
		{"slug":"fixed-image-lazy-load","name":"Fixed Image Lazy Load","value":{"style":{"opacity":0},"triggers":[{"type":"load","preload":true,"stepsA":[{"opacity":1,"transition":"opacity 100ms ease 0"}],"stepsB":[]}]}}
	]);
	/* eslint-enable */
});

$(window).scroll(function() {
	var backbutton = $('.back-to-top'),
		scroll = $(window).scrollTop();

	if (scroll >= 800) {
		backbutton.addClass('show');
	}
	else {
		backbutton.removeClass('show');
	}
});
